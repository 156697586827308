.contract {
  flex-direction: column;
}

.contract__create {
  width: 100%;
  display: inline-block;
  text-align: end;
  margin-top: 20px;
}
