.auth-form__body {
  max-width: 1500px;
  display: flex;
  justify-content: space-between;

  @include mobileScreen2 {
    flex-direction: column;
  }
}
.auth-form__body-moderator {
  min-width: 600px;

  @include mobileScreen {
    min-width: 0;
  }
}
.auth-form__body-moderator + .auth-form__buttons {
  justify-content: space-between;
}
.auth-form__body--mobile-column-reverse {
  @include mobileScreen {
    flex-direction: column-reverse;
  }
}

.auth-form__body--flex-column {
  flex-direction: column;
}

.auth-form__policy,
.auth-form__fields {
  flex-basis: 47%;
  & > *:nth-child(2n) {
    margin-top: 10px;
  }

  @include mobileScreen {
    flex-basis: 100%;
    margin-bottom: 20px;
  }
}

.auth-form__input {
  width: 100%;
  min-height: 60px;
}

.auth-form-register__input {
  margin-top: 20px !important;
}

.auth-form-register-date__input {
  margin-top: 30px !important;
}

.auth-form__input + .auth-form__input {
  margin-top: 40px;

  @include mobileScreen {
    margin-top: 30px;
  }
}

.auth-form__paragraph + .auth-form__paragraph {
  margin-top: 20px;
}

.auth-form__buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;

  @include mobileScreen {
    flex-direction: column-reverse;
    margin-top: 15px;
  }
}

.auth-form__button {
  flex-basis: 47%;
}

.auth-form__button + .auth-form__button {
  @include mobileScreen {
    margin-bottom: 15px;
  }
}

.auth-form__button--only-child {
  width: 50%;
  margin-top: 40px;

  @include mobileScreen {
    width: 100%;
  }
}

.auth-form__question {
  display: block;
  margin-top: 40px;

  @include mobileScreen {
    flex-direction: column;
    margin-top: 20px;
  }
}

.auth-form__agreement {
  margin-top: 30px;
}

.auth-form__captcha {
  margin-top: 30px;
  & > img {
    margin-bottom: 10px;
    @include mobileScreen {
      width: 100%;
    }
  }
}

.auth-form__subtitle {
  font-size: $contentFontSize;
  font-weight: 700;
  color: $textColorDark;
  margin-bottom: 20px;

  @include mobileScreen {
    margin-top: 30px;
  }
}

.form-title {
  font-size: 20px;
  line-height: 32px;
  font-weight: 700;
}

.form-subtitle {
  font-weight: 700;
  line-height: 20px;
}
