.moderator-statements-filter {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 42px;

  .filter-form__field {
    width: calc(20% - 34px);
    min-height: 60px;
    margin-top: 30px;
  }

  footer {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    h2 {
      font-weight: bold;
      font-size: 20px;
      line-height: 32px;
      color: #575756;
    }

    div {
      display: flex;
      gap: 24px;

      .button {
        padding: 10px 30px;
      }
    }
  }
}
