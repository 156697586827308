.accordion-tab {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  margin: 24px 0;

  background: #fafafa;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  align-self: stretch;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &_title {
      font-weight: bold;
      font-size: 20px;
      line-height: 32px;
      color: #575756;
    }
    &_btn {
      transition: $transitionFast;
      transition-property: transform;

      &.rotate {
        transform: scaleY(-1);
      }
      min-width: 40px;
      height: 40px;
    }
  }

  &__body {
    display: none;
    margin-top: 24px;
    width: 100%;
    transition: $transitionFast;
    transition-property: height;

    &.show {
      display: flex;
    }
  }
}
