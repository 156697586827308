@import '../../assets/styles/variables.scss';

// TODO: Вынести в общие стили
.form__body {
  display: flex;
  justify-content: space-between;
}

// TODO: Вынести в общие стили
.form__left,
.form__right {
  // composes: form__fields from '../SignInForm/SignInForm.module.css';
}

// TODO: Вынести в общие стили
.form__input {
  width: 100%;
  min-height: 60px;
  margin-top: 30px;
}

.form__input + .form__input {
  margin-top: 30px;
}

.form__subtitle {
  font-weight: $fontWeightBold;
  font-size: 16px;
}

// TODO: Вынести в общие стили
.form__buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
}

// TODO: Вынести в общие стили
.form__button {
  flex-basis: 47%;
}

.form__agreement {
  margin-top: 50px;
}

.form__checkbox + .form__checkbox {
  margin-top: 5px;
}

@media screen and (max-width: 640px) {
  .form__body {
    flex-direction: column-reverse;
  }

  .form__right {
    margin-bottom: 50px;
  }

  .form__input + .form__input {
    margin-top: 20px;
  }
}
