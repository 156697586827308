.profile-form__fields {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.profile-form__entrance-test {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.profile-form__entrance-test--full-width {
  width: 100%;
  gap: 48px;
  flex-wrap: wrap;
}

.profile-form__select {
  min-width: 200px;
}

.profile-form__field {
  flex-basis: 47%;
  min-height: 60px;
  margin-top: 30px;

  @include mobileScreen {
    flex-basis: 100%;
    margin-top: 15px;
  }
}

.profile-form__field--fb-50 {
  flex-basis: 50%;
  min-height: 60px;
  margin-top: 30px;

  @include mobileScreen {
    flex-basis: 100%;
    margin-top: 15px;
  }
}

.profile-form__field--mb-12 {
  margin-bottom: 12px;
}

.profile-form__field--mb20 {
  margin-bottom: 20px;
}

.profile-form-select__checkbox {
  margin-bottom: 20px;
}

.profile-form__subtitle {
  margin-top: 12px;
}

.profile-form__subtitle a {
  color: $accentColor;
}

.profile-form__subtitle a:hover {
  color: $accentColorHover;
}

.link_to_information {
  color: blue;
  padding-top: 15px;
}

.svg-size {
  width: 15px;
  margin-left: 5px;
}

.legal-representative-form__field {
  flex-basis: 47%;
  min-height: 60px;

  @include mobileScreen {
    flex-basis: 100%;
    margin-top: 15px;
  }
}

.legal-representative__field--container {
  flex-basis: 100%;
}

.profile-form-dadata__filed {
  margin-bottom: 30px;
  @include mobileScreen {
    margin-bottom: 15px;
    margin-top: 15px;
  }
}

.profile-form__field--fb-100 {
  flex-basis: 100%;
}

.profile-form__field--container {
  display: flex;
  justify-content: space-between;
}

.profile-form__field--containerline {
  display: flex;
}

.profile-form__field--mt-16 {
  flex-basis: 103%;
  min-height: 58px;
  margin-top: 16px;
}

.profile-form__input {
  min-height: 60px;
}

.profile-form__input--hint-with-spaces .input-container__hint {
  white-space: break-spaces;
}

.profile-form__input--fb-55 {
  flex-basis: 50%;
}

.profile-form__input--fb-50 {
  flex-basis: 47%;
}

.profile-form__input--fb-33 {
  flex-basis: 30%;
}

.profile-form__input--fb-66 {
  flex-basis: 60%;
}

.profile-form__checkbox {
  margin-top: 7px;
}

.profile-form__checkbox_fb-47 {
  margin-top: 7px;
  flex-basis: 47%;
}

.legal-representative-form__checkbox {
  margin-top: 15px;
}

.profile-form__checkbox--mt-24 {
  margin-top: 24px;
}

.profile-form__checkbox--mb-24 {
  margin-bottom: 24px;
}

.profile-form__file-uploader {
  margin-top: 24px;
}

.profile-form__radio {
  margin-top: 24px;
  min-width: 150px;
}

.profile-form__error {
  color: $dangerColor;
  font-size: 12px;
}

.profile-form__button {
  margin-top: 24px;
}

.profile-form__button--align-left {
  margin-left: auto;
}

.profile-form__missing-doc {
  margin: 0;
}

.profile-form__checkbox-missing-doc {
  margin: 0;
}

.visa__upload-doc-btn {
  width: 100%;
  margin-top: 20px;
}
.blanc__upload-doc-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 150px;
  padding: 15px;
  border-radius: 4px;
  font-size: 14px;
  text-transform: uppercase;
  color: #2b2b2b;
  font-weight: 700;
  background-color: #e5e5e5;
  transition: 0.2s ease;
  transition-property: background-color;
  cursor: pointer;
}
.blanc__upload-doc-btn:hover {
  background-color: #dddddd;
}
.blanc__upload-doc-btn:active {
  background-color: #cbcbcb;
}

.visa-title {
  margin-bottom: 20px;
}

.quota-line {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.profile-form__field--reg-number {
  flex-basis: 47%;

  @include mobileScreen {
    flex-basis: 100%;
    margin-top: 15px;
  }
}
