.template-modal-big {
  height: 70vh;
  overflow: auto;
}

.profile-form__fields-checkboxes > label:not(:last-child) {
  margin-bottom: 20px;
}
.auth-image-block__img-wrapper {
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;
}

.auth-logo-block__img-wrapper {
  position: relative;
  width: auto;
  height: 200px;
  overflow: hidden;
}

.auth-logo-flex-center__img-wrapper {
  display: flex;
  justify-content: center;
}

.auth-exchange-block__text-wrapper {
  width: 65%;
}
.auth-image-block__position-wrapper {
  margin-left: 10%;
}
.modal-body {
  height: 600px;
  overflow-y: auto;
}
.textarea {
  min-height: 250px !important;
}
.modal-block-size_exchange__text-wrapper {
  width: 40%;
}
.auth-image-block__center-text-wrapper {
  padding-top: 20px;
  width: 100%;
}
.site-setting-image-label {
  padding-top: 20px;
  margin-bottom: 5px;
}
