.progresstab_page {
  margin: 40px 40px 20px;

  &__tab-container {
    flex: 1;
    flex-direction: row;
  }

  &__tab {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    padding: 0;

    &_element {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      min-height: 72px;

      flex: 0.33;
      padding: 14px 24px;
      background: $greyColor;

      h2 {
        font-weight: bold;
        font-size: $contentFontSize;
        line-height: 24px;
      }
      p {
        font-size: 14px;
        line-height: 20px;
        text-align: center;
      }

      color: $textColorDark;

      &:hover {
        color: $accentColorHover;
      }
      &:active {
        color: $accentColorActive;
      }
      &.active {
        color: $textColorDark;
        background: $greyColorDark;
      }
      &.disabled {
        color: $accentColorDisabled;
        pointer-events: none;
      }

      &:first-child {
        border-bottom-left-radius: $borderRadiusLg;
        border-top-left-radius: $borderRadiusLg;
      }
      &:last-child {
        border-bottom-right-radius: $borderRadiusLg;
        border-top-right-radius: $borderRadiusLg;
      }
    }
  }

  &__title {
    margin: 40px 0 20px;
    font-size: 24px;
  }
  &__content {
  }
  &__inner {
  }

  @include mobileScreen {
    margin: 0 15px;

    &__title {
      margin: 30px 0;
      font-size: 24px;
    }
    &__tab {
      margin: 0 -15px;
      padding: 0 15px;
      overflow-y: auto;

      &_element {
        flex: none;

        height: 100%;
        width: min-content;
        padding: 5px 12px;

        h2 {
          min-width: max-content;
          font-size: 12px;
          line-height: 16px;
        }
        p {
          font-size: 10px;
          line-height: 14px;
        }
      }
    }
  }
}
