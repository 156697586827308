@import '../../assets/styles/variables.scss';

.footer {
  @include app-content-block;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  width: 100%;

  @include mobileScreen {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
}

.footer__link {
  font-weight: $fontWeightBold;
  text-decoration: underline;
  color: $textColorDark;

  @include mobileScreen {
    margin-bottom: 20px;
  }
}

.footer__link:hover {
  color: $accentColor;
}
