.header {
  padding: 20px;
}

.header__content {
  @include app-content-block;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header__right-corner {
  display: flex;

  .btn-link {
    margin-left: 10px;

    .button {
      padding: 6px 17px;
      min-width: 0;
      height: 100%;
    }
  }
}
.text-logo__left_corner {
  padding-top: 15px;
  padding-left: 10px;
  font-size: 13px;
  text-align: left;
  width: 186px;
  font-weight: 600;
}
.row__left_corner {
  display: flex;
}
