.pagination {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  .lang-changer__menu {
    top: auto;
    bottom: 100%;
  }

  &__btn-count {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }
  &__btn-panel {
    display: flex;
    flex-direction: row;
    gap: 4px;
    margin-left: 48px;

    &-first {
      transform: rotate(90deg);
    }
    &-last {
      transform: rotate(-90deg);
    }
  }
  .button {
    padding: 10px;
    width: 40px;
    height: 40px;
    min-width: 40px;
  }
}
