.certificate {
  &__description {
    margin-bottom: 20px;
  }

  &__first-block {
    border: 1px solid darkgray;
    width: 100%;
    padding: 20px;
    display: flex;
    gap: 20px;
    margin-bottom: 40px;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__title {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 15px;
  }

  &__average {
    display: flex;
    flex-direction: column;
    min-width: 48%;
  }

  &__second-description {
    margin-bottom: 20px;
  }

  &__second-block {
    border: 1px solid darkgray;
    width: 50%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    @media (max-width: 1024px) {
      width: 100%;
    }
  }

  &__line {
    display: flex;
    gap: 30px;
    align-items: center;
    width: 100%;

    > label {
      width: 130px;
      font-weight: 500;
    }
  }
}

@media (max-width: 1024px) {
  .certificate {
    &__first-block {
      flex-direction: column;
      gap: 10px;
    }
  }
}
