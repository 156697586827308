.moderator-statements-id-page {
  margin: 0 15px;

  &__header {
    display: flex;
    justify-content: space-between;

    &-title {
      margin: 64px 0 24px;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 32px;
    }

    &-btn {
      margin: 64px 0 24px;
    }
    &-select {
      margin: 64px 0 24px;
      width: 300px;
    }
  }

  .sidenav-page {
    margin: 0;
  }

  .moderator-contract-info {
    h2 {
      margin-bottom: 0;
    }
    .select-container {
      margin-top: 15px;
    }
    .button {
      padding: 10px 15px;
      min-width: 0;
    }
  }
}

.moderator-basic-data-page {
  .moderator-basic-data-page__image {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 24px;
    gap: 24px;

    .moderator-data-list {
      gap: 12px;

      p {
        width: 200px;
      }
    }
  }
}
.moderator-documents-page {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
