.filling-statements {
  margin-top: 24px;
  .sidenav-page__title {
    display: none;
  }
  .mb-12 {
    margin-bottom: 12px;
  }
  .mt-24 {
    margin-top: 24px;
  }
  .mb-24 {
    margin-bottom: 24px;
  }
  .mr-24 {
    margin-right: 24px;
  }
  .directions-training-list-page,
  .directions-training-create-page {
    @include mobileScreen {
      .button {
        width: 100%;
      }
    }
  }

  .directions-training-search {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 24px;

    form {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
    &__input {
      flex-basis: calc(100% - 100px);
    }
    .button {
      min-width: 75px;
    }
    &__select {
      flex: 1;
    }

    &__select--full-width {
      min-width: 100%;
    }

    &__reset-filter {
      width: 100%;
    }
    @include mobileScreen {
      gap: 10px;
      .directions-training-search__input {
        margin-right: 10px;
      }
      .button {
        width: max-content;
        padding: 10px;
      }
      .directions-training-search__select {
        flex: 1 0 100%;
      }
    }
  }

  .directions-training-element-priority {
    width: 60%;
  }

  .directions-training-element {
    display: flex;
    flex-direction: column;
    padding: 24px;
    margin-bottom: 24px;
    gap: 24px;

    width: 100%;

    font-size: 16px;
    line-height: 20px;

    color: $textColor;
    border: 1px solid $greyColorDark;
    border-radius: 4px;

    &__control-btn-container {
      align-self: flex-end;
      &_btn {
        display: flex;
        justify-content: space-between;
        gap: 8px;
        button {
          min-width: 40px;
        }
      }
    }

    .row {
      display: flex;
      justify-content: space-between;
      width: 100%;
      column-gap: 12px;
    }
    .column {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }

    .row_flex {
      flex-direction: row-reverse;
    }

    .priority-number {
      padding-top: 3%;
      padding-right: 10px;
    }

    .column-trial_type {
      margin-top: 60px;
      font-size: 14px;
      flex-basis: 25%;
    }

    .column-trial_item {
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .column-trial_item_trials {
      padding-top: 22px;
    }

    .column-flex {
      flex-basis: 30%;
      padding-top: 12px;
    }

    .column-flex-full {
      flex-basis: 100%;
    }
    .column-trial {
      flex-basis: 40%;
    }

    h2 {
      font-weight: bold;
      font-size: 20px;
      line-height: 32px;
      color: $accentColor;
    }
    &:hover {
      border-color: $accentColorHover;
      h2 {
        color: $accentColorHover;
      }
    }
    @include mobileScreen {
      margin-top: 10px;
      margin-bottom: 10px;
      gap: 10px;
      padding: 15px;
      .row {
        gap: 10px;
        flex-direction: column;
      }
      &__control-btn-container {
        width: 100%;

        .check {
          width: 100%;
        }
        &_btn {
          width: 100%;
          gap: 10px;
          .button {
            flex: 1;
          }
        }
      }
    }
  }
}

.special-quota {
  background-color: #99ff99;
}

.unit-uderline {
  font-size: 17px;
}
