.loader {
  width: 80px;
  height: 80px;
  margin: 0 auto;
}
.loader-display-block {
  display: block;
}
.loader:after {
  content: ' ';
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid $accentColor;
  border-color: $accentColor transparent $accentColor transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
.btn-loader {
  width: 16px;
  height: 16px;
  margin: 0 auto;
}
.btn-loader:after {
  width: 16px;
  height: 16px;
  margin: 0;

  border-width: 2px;
  border-color: white transparent white transparent;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
