.direction-training-card {
  background: #fafafa;
  border: 1px solid #e5e5e5;
  padding: 10px 20px;

  > h4 {
    font-weight: bold;
    margin-bottom: 20px;
  }

  > p {
    margin-bottom: 5px;
  }

  &__time {
    margin-bottom: 20px !important;
  }

  &__download-button {
    display: flex;
    gap: 15px;
    align-items: center;
  }

  &__revoke {
    margin-left: auto;
  }

  &__list {
    width: 100%;
    display: grid;
    grid-template-columns: auto;
    gap: 15px;
    margin: 20px 0;
  }
}
