.moderator-directions-element {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  padding: 24px;
  margin-bottom: 24px;
  gap: 24px;
  cursor: pointer;

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    font-weight: bold;
    font-style: normal;
    font-size: 20px;
    line-height: 32px;
    h2 {
      color: $textColorDark;
    }

    &-status {
      color: #3a79d7;

      &-deleted {
        color: $dangerColor;
      }
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .moderator-data-list {
      gap: 12px;
    }

    &-input {
      width: 47%;
      min-height: 60px;
      margin-top: 15px;
    }

    &-table {
      @extend .moderator-hallmarks-table;

      td:nth-child(3),
      td:nth-child(4) {
        width: 16%;
      }

      tbody tr:last-child {
        border-bottom: none;
      }
    }
  }
  &__info {
    display: grid;
    grid-template-columns: auto 180px;
    align-items: flex-end;
    column-gap: 12px;
  }
}
.moderator-special-quota {
  color: green !important;
}
