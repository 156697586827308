.profile-image-block__title::after {
  color: $dangerColor;
  content: '*';
}

.profile-image-block__title {
  margin-bottom: 24px;
}

.profile-image-block__error {
  font-size: 14px;
  color: $dangerColor;
  margin-top: 2px;
}

.profile-image-block__img-wrapper {
  position: relative;
  width: 210px;
  height: 280px;
  overflow: hidden;
}

.profile-image-block__img-wrapper:hover .image-controls {
  opacity: 1;
}

.profile-image-block__img {
  width: 100%;
  height: 100%;
  border-radius: $borderRadiusLg;
  object-fit: contain;
}

.profile-image-block__controls {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-bottom-left-radius: $borderRadiusLg;
  border-bottom-right-radius: $borderRadiusLg;
  overflow: hidden;
  opacity: 0;
  transition: $transitionFast;
}

.profile-image-block__controls:focus-within {
  opacity: 1;
}

.image-controls {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.image-controls__button {
  background-color: rgba(0, 0, 0, 0.8);
  padding: 10px;
  min-width: 32px;
  min-height: 32px;
  display: flex;
  align-items: center;
  color: white;
  font-weight: 700;
  width: 100%;
  cursor: pointer;
}

.image-controls__button:hover {
  color: $accentColor;
}

.image-controls__label {
  margin-left: 10px;
}

.image-controls__button--theme-rounded {
  width: unset;
  border-radius: 50%;
  padding: 0;
  justify-content: center;
  margin-left: auto;
  margin-right: 3px;
  margin-top: 3px;
}

.file-snils-block {
  margin-top: 20px;
}
