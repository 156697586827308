@import '../../../assets/styles/variables.scss';

.file-uploader__wrapper {
  display: flex;
  align-items: center;
}

.file-uploader__area {
  height: 147px;
  width: 100%;
  border: 2px dashed $greyColorDark;
  border-radius: 16px;
  color: $greySecondaryColor;
  min-width: 186px;

  svg {
    path {
      fill: $greySecondaryColor;
    }
  }
  cursor: pointer;

  &:hover {
    border-color: $greySecondaryColor;
    color: $textColor;
    svg {
      path {
        fill: $textColor;
      }
    }
  }
  &.disabled {
    border-color: $greySecondaryColor;
    color: $greySecondaryColor;
    svg {
      path {
        fill: $greySecondaryColor;
      }
    }
    opacity: 0.4;
  }
}

.file-uploader__active {
  border-style: groove;
  background-color: $greyColorDark;
}

.file-uploader {
  display: none;
}

.file-uploader__button {
  padding: 16px;
  width: 100%;
  height: 100%;
  text-align: center;
}

.file-uploader__info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 100%;
  width: 100%;
}

.file-uploader__filename {
  cursor: pointer;
  height: 80%;
  overflow-y: hidden;
  text-overflow: ellipsis;
}

.file-uploader__file-download-icon {
  width: 18px;
  height: 24px;
}

.file-uploader__files {
  display: flex;
  margin: 0 38px;
}

.file-uploader__file {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  word-break: break-all;
  width: 80px;
  height: 125px;
  font-size: 12px;
  line-height: 15px;
  margin: 0 5px;
  color: $textColor;
  text-align: center;
}

.file-uploader__file-icon {
  cursor: pointer;
  margin-bottom: 10px;
  width: 46px;
  height: 61px;
}

.file-uploader__cross {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -9px;
  right: 3px;
  width: 20px;
  height: 20px;
  cursor: pointer;

  &:hover {
    svg {
      path {
        fill: $dangerColorHover;
      }
    }
  }
  &:disabled {
    svg {
      path {
        fill: $textColor;
      }
    }
  }
}

.file-uploader__cross-icon {
  width: 15px;
  height: 15px;
}

.file-uploader__mobile-description {
  display: none;
}

@media (max-width: $mobileScreenWidth) {
  .file-uploader__area {
    min-width: none;
  }

  .file-uploader__button {
    font-size: 12px;
    line-height: 16px;
  }

  .file-uploader__file-icon {
    width: 36px;
    height: 55px;
  }

  .file-uploader__file {
    margin: 20px 0 0;
  }

  .file-uploader__cross {
    top: -17px;
    right: 0;
    width: 30px;
    height: 30px;
  }

  .file-uploader__cross-icon {
    width: 25px;
    height: 25px;
  }

  .file-uploader__files {
    margin: 0 5px;
  }

  .file-uploader__description {
    display: none;
  }

  .file-uploader__mobile-description {
    display: inline;
  }
}
