.contract-card {
  padding: 24px;
  border: 1px solid $greyColorDark;
}

.contract-card__title {
  font-weight: $fontWeightBold;
  color: $textColorDark;
  font-size: 20px;
  margin-bottom: 20px;
}

.contract-card__payment-type {
  margin-bottom: 24px;
}

.contract-card__payment-type--bold {
  font-weight: $fontWeightBold;
}

.contract-card__download {
  display: flex;
  margin-bottom: 24px;
}

.contract-card__download-button {
  min-width: 220px;
  margin-right: 20px;
}

.contract-card__subtitle {
  font-weight: $fontWeightBold;
  margin-bottom: 24px;
}

.contract-card__footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.contract-card__uploader {
  margin-bottom: 24px;
}

.contract-card__button + .contract-card__button {
  margin-left: 24px;
}
