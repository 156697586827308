html {
  font-family: $baseFontFamily;
  font-weight: $fontWeightNormal;
  font-size: $contentFontSize;
  color: $textColor;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;

  @include tabletScreen {
    font-size: 14px;
  }
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
