.result-exam {
  display: flex;
  flex-direction: column;
  gap: 24px;
  &__btn {
    display: flex;
    gap: 24px;
  }
  &__element {
    background: #ffffff;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    gap: 24px;
    padding: 24px;
    &_field {
      flex: 1;
    }
    &_status {
      align-items: center;
      height: 40px;
      width: 100px;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      color: $accentColor;
      svg {
        height: 20px;
      }
      .button {
        min-width: 0;
        height: 40px;
      }
    }
  }

  &__document {
    display: flex;
    flex-direction: row;
    gap: 24px;
    flex-wrap: wrap;

    &-field {
      align-items: center;
      width: calc(50% - 12px);
      margin-top: 18px;
      margin-bottom: 18px;

      &-full-width {
        width: 100%;
      }
    }

    &-container {
      display: flex;
      width: calc(50% - 12px);
      margin-top: 0;
      gap: 24px;
    }
  }
}

.anotherPassports {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
