.trajectory-list {
  margin-top: 24px;
  margin-bottom: 24px;
  display: flex;
  gap: 24px;
  flex-wrap: wrap;

  &__trajectory-element {
    flex: 0.32;
    box-sizing: border-box;
    padding: 24px;
    border: 1px solid #e5e5e5;
    border-radius: $borderRadiusSm;
    background: $whiteColor;
    color: $textColorDark;

    ul li {
      margin-top: 16px;
      margin-left: 24px;
      list-style: disc;
      &.first {
        list-style: none;
        & > p {
          font-weight: bold;
        }
      }
      &.number {
        margin-left: 24px;
        list-style: none;
      }
      &:first-letter {
        text-transform: uppercase;
      }
    }
    .link {
      color: $accentColor;
      &:hover {
        color: $accentColorHover;
      }
      &:active {
        color: $accentColorActive;
      }
    }
    &:hover {
      color: $accentColorHover;
      border-color: $accentColorHover;
    }
    &:active {
      color: $accentColorActive;
      border-color: $accentColorActive;
    }
    &.active {
      background: $accentColor;
      border-color: $accentColor;
      color: $whiteColor;

      & .link {
        color: $whiteColor;
        &:hover {
          color: $greyColorDarkHover;
        }
        &:active {
          color: $greyColorDarkPressed;
        }
      }
    }
    &.disabled {
      color: $accentColorDisabled;
      border-color: $accentColorDisabled;
      background: $greyColor;
      pointer-events: none;
    }

    h2 {
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 32px;
    }

    p {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 16px;
    }
  }
  @include mobileScreen {
    margin-bottom: 30px;
    gap: 15px;
    &__trajectory-element {
      padding: 15px 10px;
      flex: unset;
      width: calc(100% + 20px);
      margin-right: -10px;
      margin-left: -10px;
    }

    .button {
      width: 100%;
    }
  }
}
