.profile-auth-block__input,
.profile-auth-block__title {
  margin-bottom: 24px;
}

.profile-auth-block__input {
  min-height: 70px;

  @include mobileScreen {
    margin-bottom: 0;
  }
}

.profile-auth-block__button {
  @include mobileScreen {
    width: 100%;
  }
}
