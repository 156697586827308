.admin-user-element {
  display: flex;
  flex-direction: row;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  padding: 24px;
  margin-bottom: 24px;
  gap: 24px;

  &__info {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 16px;

    h2 {
      font-weight: bold;
      font-style: normal;
      font-size: 20px;
      line-height: 32px;
      color: $textColorDark;
      &:first-letter {
        text-transform: uppercase;
      }
    }
    a {
      color: $accentColor;

      &:hover {
        text-decoration: underline;
      }
    }
  }
  &__actions {
    display: flex;
    flex-direction: column;
    width: 50%;

    h2 {
      padding-left: 15px;
      margin-bottom: 16px;
      font-size: 20px;
      line-height: 32px;
      font-weight: bold;
      font-style: normal;
      color: $textColor;
    }

    &-btn {
      width: 100%;
      padding: 15px;
      color: $textColor;
      font-weight: bold;
      font-style: normal;
      border-bottom: 1px solid $accentColor;
      cursor: pointer;
      &:hover {
        outline: 1px solid $accentColor;
        background: $greyColor;
      }
    }
  }
}
