.personal-data-form__top {
  display: flex;
  margin-top: 24px;

  @include mobileScreen {
    flex-direction: column-reverse;
    align-items: center;
    margin-top: 0;
  }
}

.personal-data-form__auth {
  margin-left: 60px;
  width: 50%;

  @include mobileScreen {
    margin-left: 0;
    margin-bottom: 20px;
    width: 100%;
  }
}

.personal-data-form__title {
  @include mobileScreen {
    display: none;
  }
}

.download__field {
  flex-direction: column;

  &-links {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
}

.snils-upload {
  margin-top: 10px;

  & .profile-section__body {
    margin-top: 10px;
  }
}

.no-date-snils {
  margin-bottom: 15px;
}

div.position {
  margin-top: 0;
}
