@import '../../../assets/styles/variables.scss';

.input-container {
  position: relative;
  color: $greySecondaryColor;
}

.input-container__inner {
  position: relative;
}

.input-container__masked .error {
  position: absolute;
  bottom: -17px;
  left: 0;
  color: $dangerColor;
  font-size: 12px;
}

.input-container__input {
  width: 100%;
  padding: 10px 0;
  border-bottom-width: 1px;
  border-color: $greySecondaryColor;
  max-height: 39px;
  color: $textColorDark;
}

.input-container__input:disabled + .input-container__placeholder,
.input-container__input:disabled {
  opacity: 0.4;
}

.input-container__input--error {
  border-color: $dangerColor;
}

.input-container__input:focus {
  outline: none;
  border-color: $accentColor;
}

.input-container__placeholder {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  transition: transform 150ms ease-out, font-size 150ms ease-out;
  cursor: text;
  user-select: none;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.textarea-container {
  width: 500px;
  .input-container__placeholder {
    top: 0;
    transform: translateY(0%);
  }
  &.input-container--active .input-container__placeholder {
    font-size: 14px;
    transform: translateY(-150%);
  }

  .input-container__input.textarea {
    min-height: 40px;
    max-height: 200px;
    outline: none;
    -moz-appearance: none;
    overflow: hidden;
    resize: none;
    padding: 0;
  }
}

.input-container__placeholder--required::after {
  color: $dangerColor;
  content: '*';
}

.input-container--active .input-container__placeholder {
  transform: translateY(-185%);
  font-size: 12px;
}

.input-container__icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}

.input-container__icon:hover {
  color: $accentColor;
}

.input-container__icon:active {
  color: unset;
}

.input-container__error,
.input-container__hint {
  margin-top: 5px;
  color: $greySecondaryColor;
  font-size: 12px;
}

.input-container__error {
  color: $dangerColor;
}

.input-container__search-icon {
  position: absolute;
  right: 0;
  bottom: 10px;
}
