.signCheckboxes {
  display: flex;
  flex-direction: column;
  gap: 12px;

  padding-top: 12px;

  // signCheckboxes_error
  &_error {
    color: #ff2b2b;
    font-size: 12px;
  }
}

.globalError {
  color: #ff2b2b;
  font-size: 18px;
}

.sspvo_link {
  text-decoration: underline;
}
