.moderator-contract-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  margin: 24px 0;

  background: $greyColor;
  border: 1px solid $greyColorDark;
  border-radius: 4px;

  gap: 24px;

  &__inline {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  &__block-row {
    display: flex;
    justify-content: space-between;

    span {
      width: 65%;
    }
  }
  &__block {
    display: flex;
    flex-direction: column;
    gap: 12px;
    color: $textColor;
    width: 100%;

    h2 {
      font-weight: 700;
      font-size: 20px;
      line-height: 32px;
      margin-bottom: 12px;
    }

    p {
      font-size: 16px;
      line-height: 20px;
      color: $textColor;
    }

    span {
      color: $textColorDark;
      font-weight: bold;
    }
  }

  &__block_file-container {
    display: flex;
    flex-wrap: wrap;
    gap: 24px 48px;
  }

  &__select {
    width: 400px;
    min-height: 60px;
    margin-top: 16px;
  }

  &__input {
    width: 400px;
    min-height: 60px;
    margin-top: 16px;
  }

  &__footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    &-btn {
      display: flex;
      gap: 12px;

      &-reject {
        background: none;
      }
    }
  }
}

.upload-contract__button {
  margin-top: 20px;
}
.upload-contract__block-button {
  width: fit-content;
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 20px;
}

.moderator-contract__header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
