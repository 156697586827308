.students-form {
  width: 100%;
  height: auto;
  background: $greyColor;
  border-radius: 4px;
  margin-top: 20px;
  padding: 24px;
  border: 1px solid $greyColorDark;
  &__fields {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
    column-gap: 40px;
  }

  &__field {
    flex-basis: 290px;
    min-height: 60px;
    margin-top: 30px;
  }

  &__amount {
    margin-top: 42px;
    display: flex;
    font-weight: bold;
    font-size: 20px;
    line-height: 32px;
    color: $textColor;
  }

  &__amount span:first-child {
    margin-right: 24px;
  }
}
