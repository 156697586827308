.card {
  width: 100%;
  height: auto;
  display: grid;
  padding: 24px;
  grid-template-rows: auto;
  row-gap: 24px;
  background: $whiteColor;
  border: 1px solid $greyColorDark;

  &__head {
    width: 100%;

    display: flex;
    justify-content: space-between;
  }

  &__header {
    color: $textColorDark;
    font-size: 20px;
    font-weight: $fontWeightBold;
  }

  &__download {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 24px;
    align-items: center;
  }

  &__signature {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  &__content-direction {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 5px;
  }
}
