.rcw-widget-container {
  .rcw-conversation-container {
    width: 370px;

    .rcw-header {
      background-color: $accentColor;
    }

    .rcw-messages-container {
      .rcw-message-text {
        padding: 10px;
        background-color: $greyColorDark;
        max-width: 75%;
        min-width: 116px;

        p {
          color: black;

          &:last-child {
            color: #575756;
            margin-top: -8px;
            text-align: right;
            font-size: 0.8rem;
          }
        }
      }

      .rcw-response {
        width: 100%;

        .rcw-message-text {
          p {
            &:first-child {
              color: #575756;
              margin-bottom: -12px;
              font-size: 0.8rem;
            }
          }
        }
      }

      .rcw-client {
        align-items: end;
        width: 100%;
      }
    }

    .quick-buttons-container {
      .quick-buttons {
        .quick-list-button {
          .quick-button {
            border-color: $accentColor;
            font-size: 0.9rem;
            color: $textColor;

            &:active {
              background-color: $accentColor;
            }
          }
        }
      }
    }

    .rcw-sender {
      display: flex;
      justify-content: space-around;
      align-items: center;
      max-height: initial;

      .rcw-new-message {
        width: 100%;
        margin-right: 1rem;
        padding: 0.75rem;
        overflow-y: auto;

        .rcw-input {
          cursor: text;
          min-height: 21px;
          overflow-y: initial;

          &:focus {
            outline: none;
          }
        }
      }
    }
  }

  .rcw-launcher {
    background-color: $accentColor;

    .rcw-open-launcher,
    .rcw-close-launcher {
      display: flex;
      margin: auto;
    }
  }
}
