.level-list {
  margin-top: 24px;
  margin-bottom: 24px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 24px;

  &__level-element {
    box-sizing: border-box;
    padding: 24px;
    border: 1px solid $greyColorDark;
    border-radius: $borderRadiusSm;
    background: #d49361;
    color: $textColorDark;
    white-space: pre-wrap;
    text-align: center;
    flex-grow: 1;

    ul li {
      margin-top: 16px;
      margin-left: 24px;
      list-style: disc;
    }
    ol li {
      margin-left: 24px;
      list-style: decimal;
    }
    &.one-block {
      flex-basis: 100%;
      background-color: #34c3eb;
    }
    &.two-blocks {
      flex-basis: 45%;
      background-color: #34c3eb;
    }
    &.other {
      flex-grow: initial !important;
    }

    &:hover {
      border-color: $accentColorHover;
    }
    &:active {
      color: $accentColorActive;
      border-color: $accentColorActive;
    }
    &.active {
      border-color: $accentColor;
      color: $whiteColor;
    }
    &.disabled {
      color: $accentColorDisabled;
      border-color: $accentColorDisabled;
      background: $greyColor;
      pointer-events: none;
    }

    h2 {
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 32px;
    }
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 16px;
    }
  }

  @include mobileScreen {
    margin-bottom: 30px;
    gap: 15px;
    &__level-element {
      padding: 15px 10px;
      flex: unset;
      width: calc(100% + 20px);
      margin-right: -10px;
      margin-left: -10px;
    }

    .button {
      width: 100%;
    }
  }
}
