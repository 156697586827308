.moderator-file {
  display: flex;
  align-items: center;

  font-size: 16px;
  line-height: 20px;
  color: #3a79d7;

  h5 {
    font-weight: bold;
  }
  p {
    color: #575756;
    font-weight: normal;
  }

  &:hover {
    color: $accentColorHover;
    h5 {
      color: $accentColorHover;
    }
  }
  &:active {
    h5 {
      color: $accentColorActive;
    }
    color: $accentColorActive;
  }
}
