.template-auth-form {
  display: flex;
  flex-direction: column;
  //  align-items: center;
}

.auth-form__alreadyHave {
  margin: 20px 0 20px 0;
  color: #7e7e7e;
}

.auth-form-row-message {
  max-width: 1500px;
  //  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  //@include mobileScreen {
  //  flex-direction: column;
  //}
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
}

.auth-login__input {
  flex-basis: 47%;
}

//@media screen and (max-width: 935px) {
//  .auth_invite_text_message {
//    top: 25%;
//    font-size: 21px;
//    flex-direction: column;
//  }
//}
.auth-form__forgotten {
  font-weight: 700;
  font-size: 16px;
}

.auth-form__join {
  margin-bottom: 30px;
  margin-top: 10px;
  font-size: 15px;
  font-weight: 700;
  color: #2b2b2b;
  // line-height: 65px;
}

.right-cell {
  margin-left: 10px;
}
.left-cell {
  margin-right: 10px;
}

.auth-form__image {
  position: relative;
}

.auth-invite__link {
  color: #343434 !important;
}

.auth_invite_text_message {
  position: absolute;
  top: 26%;
  left: 45%;
  font-weight: 800;
  font-size: 21px;
  //text-transform: uppercase;
  color: white;
  /* width: 350px; */
  //background: rgba(0, 0, 0, 0.5803921569);
  padding: 10px;
  //text-align: center;
  //font: bold 24px/34px Helvetica, Sans-Serif;\
  margin-right: 5%;
}
@media screen and (max-width: 1360px) {
  .auth_invite_text_message {
    top: 20%;
  }
}
@media screen and (max-width: 1280px) {
  .auth_invite_text_message {
    top: 10%;
  }
}
@media screen and (max-width: 1150px) {
  .auth_invite_text_message {
    top: 5%;
  }
}
@media screen and (max-width: 1065px) {
  .auth_invite_text_message {
    font-size: 18px;
  }
}
@media screen and (max-width: 935px) {
  .auth_invite_text_message {
    top: 25%;
    font-size: 21px;
  }
}
@media screen and (max-width: 640px) {
  .auth_invite_text_message {
    //visibility: visible;
    top: 15%;
    font-size: 18px;
  }
}
@media screen and (max-width: 495px) {
  .auth_invite_text_message {
    top: 5%;
  }
}
@media screen and (max-width: 463px) {
  .auth_invite_text_message {
    top: 5%;
    font-size: 15px;
  }
}
@media screen and (max-width: 399px) {
  .auth_invite_text_message {
    visibility: hidden;
  }
}
.text-wrap-view {
  white-space: pre-wrap;
}

.template-auth-form__message {
  flex-basis: 47%;
  display: flex;
  height: 100%;
  //justify-content: space-between;
  align-items: center;
  //max-width: 680px;
  border: 1px solid $accentColor;
  border-radius: 10px;
  padding: 28px 10px 32px 34px;
  margin-bottom: 24px;

  &-item:not(:first-child) {
    margin-left: 16px;
    font-weight: 700;

    & > p:first-child {
      color: $accentColor;
    }
  }
}

.template-auth-form__title {
  margin-bottom: 20px;
  text-align: center;
  font-size: 50px;
  font-weight: $fontWeightBold;
  color: $textColorDark;
  line-height: 65px;
  padding-bottom: 40px;
  @include tabletScreen {
    font-size: 32px;
    line-height: 45px;
    padding-bottom: 20px;
  }

  @include mobileScreen {
    font-size: 24px;
    font-weight: normal;
    line-height: unset;
    align-self: flex-start;
    text-align: left;
  }
}

.template-auth-form__content {
  max-width: 680px;
  background-color: $greyColor;
  padding: 24px;
  border-radius: $borderRadiusSm;

  @include mobileScreen {
    background-color: unset;
    padding: 0;
  }
}

.text-left {
  margin-left: 20px;
}
