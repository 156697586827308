.contract-list-page__header {
  display: flex;
  margin-bottom: 24px;
}

.contract__info {
  &-first {
    margin-bottom: 20px;
  }
  &-bold {
    font-weight: bold;
  }
  &-italic {
    font-style: italic;
  }
  &-underline {
    text-decoration: underline;
  }
  &-list {
    list-style-type: decimal;
    padding-left: 40px;
    margin-bottom: 20px;
  }
  &-link {
    color: #3a79d7;
    text-decoration: underline;
  }
  &-center {
    text-align: center;
  }
}

.contract__section {
  margin-top: 24px;
  margin-bottom: 24px;
}
