.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  & > button {
    width: 200px;
    margin-top: 30px;
  }
}

.modal__link {
  color: #3a79d7;
}
