@import '../../assets/styles/variables.scss';

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.app__body {
  flex: 1;
  margin-bottom: 50px;
  border-top: 1px solid $greyColorDark;
}
