.privileges-page {
  display: grid;
  grid-template-rows: auto;
  row-gap: 24px;

  &__title {
    color: $textColor;
    font-weight: $fontWeightBold;
    font-size: 16px;
  }
}

.lgota-file-uploader {
  margin-bottom: 20px;
}

.special-docs__container {
  margin-bottom: 20px;
  &:not(:first-child) {
    margin-top: 20px;
  }
}

.special-docs__link {
  color: $accentColor;
  &:hover {
    color: $accentColorHover;
  }
  &:active {
    color: $accentColorActive;
  }
}
