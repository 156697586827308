.toast-container {
  width: fit-content;
  position: sticky;
  z-index: 9999;
  top: 5px;
  left: 50%;
  display: flex;
  justify-content: center;
}

.toast-container__items {
  position: absolute;
}

.toast-container__item {
  margin: 10px;
}
