.statements-container {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;

  &__element {
    display: flex;
    flex-direction: column;
    width: calc(50% - 12px);
    padding: 24px;
    background: #ffffff;
    border: 1px solid $greyColorDark;
    box-sizing: border-box;
    border-radius: 4px;
    gap: 24px;

    h2 {
      font-weight: bold;
      font-size: 20px;
      line-height: 32px;
      color: $textColorDark;
    }

    &-text {
      display: flex;
      flex-direction: column;
      gap: 12px;
      p {
        font-weight: normal;
        font-size: $contentFontSize;
        line-height: 20px;
        color: $textColor;

        b.success {
          color: $accentColor;
        }
      }
    }

    &-btn-panel {
      display: flex;
      gap: 24px;

      &_icon {
        min-width: 40px;
      }
    }
    &:hover {
      border-color: $accentColorHover;
      h2 {
        color: $accentColorHover;
      }
    }
    &:active {
      border-color: $accentColorActive;
      h2 {
        color: $accentColorActive;
      }
    }
  }

  @include tabletScreen {
    &__element {
      width: 100%;

      .button {
        margin-top: 10px;
      }
    }
  }

  @include mobileScreen {
    gap: 10px;

    &__element {
      width: 100%;

      .button {
        margin-top: 10px;
      }
    }
  }
}
