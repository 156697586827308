.moderator-exam-result-page {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .button {
    margin-left: auto;
  }

  h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 32px;
    color: $textColor;
  }
  .moderator-data-list {
    gap: 12px;
  }

  .moderator-exam-result-table {
    @extend .moderator-hallmarks-table;
    td:nth-child(3),
    td:nth-child(4) {
      min-width: 0;
    }
  }
}
