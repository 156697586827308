.table-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #fafafa;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  align-self: stretch;

  width: max(100%, 100vw - 40px);
  margin: 24px 0 24px calc(50% - 50vw + 20px);

  &__radio-btn {
    padding: 10px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;

    color: $textColorDark;

    &.active {
      color: #ffffff;
      background: #3a79d7;
      border-radius: 48px;
    }
  }

  header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 24px;

    div {
      display: flex;
      flex-direction: row;

      p {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 8px;
      }
    }
  }

  &__main {
    width: 100%;
    overflow: auto;
  }
  &__body {
    min-width: 100%;
    border-collapse: collapse;

    font-size: 12px;
    tr {
      border-bottom: 1px solid $greyColorDark;
    }
    thead tr {
      background-color: $greyColor;
    }
    thead tr th {
      text-align: start;
    }

    tbody tr:nth-child(odd) {
      background: #ffffff;
    }
    tbody tr:nth-child(even) {
      background: $greyColor;
    }

    tbody tr:hover {
      background: #e5e5e5;
    }
    tbody tr:active {
      background: #dddddd;
    }
    th,
    td {
      padding: 9px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: $textColorDark;
    }

    th {
      color: $textColor;
      font-weight: normal;
    }

    td:nth-child(4),
    td:nth-child(7),
    td:nth-child(6) {
      white-space: nowrap;
    }

    th {
      text-align: center;
    }
  }
}
