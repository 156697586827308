.check {
  position: relative;
  display: flex;
  padding-left: 1.3em;
}

.check__box {
  position: absolute;
  width: 0.9em;
  height: 0.9em;
  margin-left: -1.3em;
  margin-top: 0.1em;
  border-radius: 2px;
  border-width: 1px;
  border-color: $textColor;
  cursor: pointer;
}

.check__input:focus + .check__box {
  box-shadow: 0 0 0 3px $accentColor;
}

.check__input:checked:focus + .check__box {
  box-shadow: 0 0 0 3px $greySecondaryColor;
}

.check__input:checked + .check__box {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 24 24'%3E%3Cpath d='M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z' fill='white' /%3E%3C/svg%3E");
  background-color: $accentColor;
  background-position: center center;
  background-repeat: no-repeat;
  border-color: $accentColor;
}

.check__input:disabled + .check__box {
  opacity: 0.3;
  pointer-events: none;
  user-select: none;
}

.check--theme-success-filled {
  align-items: center;
  background: #e5e5e5;
  border-radius: 4px;
  padding: 10px 10px 10px calc(1.3em + 10px);
  width: fit-content;

  .check__input:focus + .check__box {
    box-shadow: none;
  }

  .check__input:checked:focus + .check__box {
    box-shadow: none;
  }

  .check__box {
    border-color: $textColorDark;
  }
  span {
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
    color: $textColorDark;
  }
  &:hover {
    background-color: #dddddd;
  }
  &:active {
    background-color: #cbcbcb;
  }
  .check__input:checked + .check__box {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 24 24'%3E%3Cpath d='M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z' fill='green' /%3E%3C/svg%3E");
    background-color: #ffffff;
    background-position: center center;
    background-repeat: no-repeat;
    border-color: #ffffff;
  }
  &.active {
    background-color: $accentColor;

    span {
      color: #ffffff;
    }
  }
  &.disabled {
    pointer-events: none;
    user-select: none;
    background-color: $accentColorDisabled;
    span {
      color: $greySecondaryColor;
    }
  }
}
.check__input {
  @include visually-hidden;
}
