.not-found-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 90px;
  gap: 24px;

  h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    color: $textColorDark;
  }

  @include mobileScreen {
    margin: 90px 5px;
    &__btn {
      width: 100%;
    }
  }
}
