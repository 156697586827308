@import '../../../assets/styles/variables.scss';

.compatriot__button {
  background: $greyColorDark;
  font-size: 14px;
  font-weight: $fontWeightBold;
  text-transform: uppercase;
  border-radius: 4px;
  padding: 10px;

  &:hover {
    background-color: $greyColorDarkHover;
  }

  &:active {
    background-color: $greyColorDarkPressed;
  }
}

.compatriot__title {
  margin: 24px 0;
  font-weight: $fontWeightBold;
  font-size: 16px;

  &::after {
    content: '*';
    position: absolute;
    color: $dangerColor;
  }
}

.form > *:nth-child(3) {
  margin-top: 30px;
}
