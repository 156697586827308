.entr-exams__text {
  line-height: 20px;
  margin-bottom: 30px;
}

.entr-exams__prompt {
  background-color: white;
  border: 1.5px solid black;
  padding: 15px;
  border-radius: 20px;
  margin-bottom: 20px;
  &-text {
    font-weight: bold;
    margin: 0;
  }
}

.entr-exams__form {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.entr-exams__texts {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: auto;
}

.entr-exams-table {
  width: 100%;
  border-collapse: collapse;
}

.entr-exams-table__head-cell {
  font-weight: $fontWeightNormal;
  font-size: 14px;
  line-height: 20px;
  vertical-align: middle;
  padding: 10px 0;
  width: 25%;
}

.entr-exams-table__cell {
  width: 25%;
  vertical-align: middle;
  padding: 20px 0;
}

.entr-exams-table__head-cell--align-center,
.entr-exams-table__cell--align-center {
  text-align: center;
}

.entr-exams-table__row {
  border-bottom: 1px solid $greyColorDark;
}

.entr-exams-table__estimate,
.entr-exams-table__discipline {
  font-weight: $fontWeightBold;
  color: $textColorDark;
}

.entr-exams-table__date {
  text-transform: uppercase;
  color: $accentColorDisabled;
  font-weight: $fontWeightBold;
}
