.sidenav-page {
  margin: 0 40px;

  @include mobileScreen {
    margin: 0 5px;
  }
}

.sidenav-page__title {
  margin: 40px 0 20px;
  font-size: 24px;

  @include mobileScreen {
    margin-left: 10px;
  }
}

.sidenav-page__content {
  display: flex;

  @include tabletScreen {
    flex-direction: column;
  }
}

.sidenav-page__nav-container {
  flex: 1;
}

.sidenav-page__inner {
  flex: 1.7;
  padding: 24px;
  border: 1px solid $accentColor;
  border-radius: $borderRadiusSm;
  background-color: $greyColor;
  min-height: 50vh;

  @include mobileScreen {
    padding: 12px;
    border-color: $greyColorDark;
  }
}

.sidenav-page__button {
  margin-left: auto;
  margin-top: 12px;
}
