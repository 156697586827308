.sidenav-link {
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-top-left-radius: $borderRadiusSm;
  border-bottom-left-radius: $borderRadiusSm;
  transition: $transitionFast;
  transition-property: color;

  @include tabletScreen {
    border-radius: $borderRadiusSm;
  }
}

.sidenav-link:hover {
  color: $accentColor;
}

.sidenav-link__title {
  font-weight: $fontWeightBold;
  margin-bottom: 5px;
}

.sidenav-link__icon {
  width: 20px;
  height: 18px;
  margin-right: 3px;
}

.sidenav-link__icon--check {
  color: $accentColor;
  height: 16px;
}

.sidenav-link__icon--exclamation {
  color: $dangerColor;
}
.sidenav-link__icon--exclamation-null {
  color: #ffbf2b;
}
.sidenav-link.active {
  background-color: $accentColor;
  color: white;
}

.sidenav-link__text {
  line-height: 1.3;
}
.inlineExclamation {
  display: inline;
}
