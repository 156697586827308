.change-password-modal__field {
  width: 280px;
  margin-top: 30px;
  min-height: 60px;
}

.change-password-modal__field--min-h-90 {
  min-height: 90px;
}

.change-password-modal__button {
  margin-top: 40px;
  width: 100%;
}
