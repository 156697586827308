.modal-layer {
  z-index: 5000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.modal-layer__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

.modal-layer__modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @include mobileScreen {
    width: 95vw;
  }
}

.template-modal {
  background-color: $greyColor;
  border-radius: $borderRadiusSm;
  padding: 24px;

  @include mobileScreen {
    padding: 12px;
    top: 5%;
    left: 2.5%;
    right: 2.5%;
    bottom: 5%;
    transform: none;
    width: auto;
    overflow: auto;
  }
}

.template-modal__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.template-modal__title {
  font-size: 24px;
  font-weight: $fontWeightBold;
  color: $textColorDark;
  margin-right: 24px;
}

.template-modal__close-btn {
  min-width: unset;
  width: 40px;
  height: 40px;
  font-size: 18px;
  text-transform: unset;
}
