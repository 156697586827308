.user-menu {
  display: flex;
  margin-left: 48px;

  @include mobileScreen {
    margin-left: 20px;
  }
}

.user-menu__info {
  margin: 0 8px;

  @include mobileScreen {
    display: none;
  }
}

.user-menu__info-moderator {
  display: flex;
  align-self: center;
  justify-self: center;

  @include mobileScreen {
    display: none;
  }
}

.user-menu__icon {
  height: 40px;
  width: 40px;
  border-radius: 50%;

  @include mobileScreen {
    display: none;
  }
}

.user-menu__name {
  font-weight: $fontWeightBold;
}
.user-menu__name-moderator {
  display: flex;
  align-self: center;
  justify-self: center;
}
.user-menu__logout {
  display: flex;
  justify-content: center;
  align-items: center;
  background: $greyColorDark;
  border-radius: 4px;
  width: 40px;
  height: 40px;
  cursor: pointer;

  &:hover {
    background: $greyColorDarkHover;
  }

  &:active {
    background: $greyColorDarkPressed;
  }
}
