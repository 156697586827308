.add-files-page__wrapper {
  margin: 0 40px;

  @include mobileScreen {
    margin: 0 5px;
  }
}

.add-files-page__title {
  margin: 40px 0 20px;
  font-size: 24px;

  @include mobileScreen {
    margin-left: 10px;
  }
}

.add-files-page__body {
  padding: 24px;
  border: 1px solid $greyColorDark;
  border-radius: $borderRadiusSm;
  background-color: $greyColor;

  @include mobileScreen {
    padding: 12px;
  }
}

.add-files-page__items {
  margin-top: 20px;
}

.add-files-page__item {
  margin: 10px 0;
}

.additional-file {
  display: flex;
  align-items: center;
}

.additional-file__name {
  font-weight: $fontWeightBold;
  color: $accentColor;
  margin-bottom: 5px;
}

.additional-file__icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 80px;
  height: 80px;
}

.additional-file__button {
  color: $accentColor;
  cursor: pointer;
}

.additional-file__del-button {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 5px;
}
