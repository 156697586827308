.template-modal__content {
  display: flex;
  margin-top: 20px;

  & > button:not(:first-child) {
    margin-left: 20px;
  }
}

.template-modal__text {
  margin-top: 15px;
}
