.radio-button {
  display: flex;
  flex-direction: column;
  padding: 24px;
  border-radius: $borderRadiusLg;
  border: 1px solid $greyColorDark;
  width: max-content;
}

.radio-button__label {
  display: flex;
  align-items: center;
  margin: 5px 0;
}

.radio-button__text {
  margin-left: 10px;
}
