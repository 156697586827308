.table-incoming-students {
  width: 100%;
  border: 1px solid $greyColorDark;
  border-radius: 4px;
  margin-top: 24px;

  &__link {
    min-height: 80px;
    font-weight: bold;
    cursor: pointer;
    font-size: 20px;
    line-height: 32px;
    color: $textColorDark;
    border-bottom: 1px solid $greyColorDark;
    padding: 24px;
  }

  &__link:hover {
    color: $accentColorHover;
  }
  &__link:active {
    color: $accentColorActive;
  }
}
