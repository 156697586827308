.profile-section + .profile-section {
  margin-top: 30px;
}

.profile-section__heading {
  display: flex;
  align-items: center;
  & > *:not(:first-child) {
    margin-left: 10px;
  }
}

.profile-section__heading-non-tooltip {
  align-items: start;
  flex-direction: column;

  p {
    margin: 4px 0 0 0 !important;
  }
}

@keyframes fadeIn {
  99% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}

.profile-section__svg {
  position: relative;
  &:hover::after {
    content: attr(title-hint);
    border: 1px solid $accentColor;
    position: absolute;
    background-color: white;
    padding: 15px;
    border-radius: 20px;
    min-width: 270px;
    word-wrap: break-word;
    top: -350%;
    left: 10px;
    visibility: hidden;
    animation: 0.5s fadeIn;
    animation-fill-mode: forwards;

    @include mobileScreen {
      left: -400%;
    }
  }
}

.profile-section__title {
  font-weight: bold;
}

.profile-section__hint {
  font-weight: bold;
  font-size: 14px;
}

.profile-section__title--required::after {
  color: $dangerColor;
  content: '*';
}
