.add-file-modal__input {
  margin: 40px 0;
}

.add-file-modal__button {
  margin-top: 24px;
  margin-left: auto;
}

.add-file-modal__error {
  color: $dangerColor;
  font-size: 12px;
}

.add-file-modal__uploader {
  margin-top: 24px;
}
