.directory-page {
  margin: 0 15px;
  .directory-page-header {
    margin: 64px 0 24px;
    display: flex;
    justify-content: space-between;
  }
  &__title {
    font-size: 24px;
    line-height: 32px;
  }
  &__pagination {
    margin-top: 20px;
  }
}
.settings-page-header {
  margin-top: 64px;
}
.edit-line-row {
  display: flex;
  justify-content: space-between;
}
.with_status {
  border-top: 1px solid #e5e5e5;
}
.status-exchange-row {
  margin: 15px 15px 15px 20px;
  font-size: 17px;
}
