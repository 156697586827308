.edit-profile-img-modal {
  max-height: 80vh;
  overflow: auto;

  @include mobileScreen {
    max-height: none;
  }
}

.edit-profile-img-modal__cropper {
  margin-top: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 500px;
}

.edit-profile-img-modal__controls {
  display: flex;
  justify-content: center;
}

.edit-profile-img-modal__control {
  min-width: unset;
  width: 40px;
  height: 40px;
  padding: 0;
  font-size: 18px;
  text-transform: unset;
  margin: 4px;
}

.edit-profile-img-modal__control:disabled {
  pointer-events: none;
  opacity: 0.4;
}

.edit-profile-img-modal__file-input {
  @include visually-hidden;
}

.edit-profile-img-modal__footer {
  display: flex;
  justify-content: space-evenly;
  margin-top: 48px;
}

.edit-profile-img-modal__button {
  cursor: pointer;
}

.edit-profile-img-modal__button + .edit-profile-img-modal__button {
  margin-left: 24px;
}
