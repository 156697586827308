.moderator-data-list {
  display: flex;
  flex-direction: column;
  gap: 24px;

  &__element {
    display: flex;
    flex-direction: row;

    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    gap: 24px;
    p {
      width: 275px;
      font-weight: normal;
      color: $textColor;
      flex-shrink: 0;
    }
    span {
      font-weight: bold;
      color: $textColorDark;
    }
  }
}
