.administration-page {
  margin: 0 15px;

  .administration-page-header {
    display: flex;
    justify-content: space-between;
    margin: 64px 0 24px;
  }
  &__title {
    font-size: 24px;
    line-height: 32px;
  }

  .administration-page-table {
    border-radius: 4px;
    width: 100%;
    margin-bottom: 24px;
    border-collapse: collapse;

    tbody tr {
      background: #fafafa;
      border: 1px solid #e5e5e5;
    }

    th,
    td {
      padding: 12px 6px;
    }

    th:first-child,
    td:first-child {
      padding-left: 12px;
    }

    th:last-child,
    td:last-child {
      padding-right: 12px;
    }
    .buttons {
      display: flex;

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        color: $textColorDark;
      }

      button:hover {
        color: $accentColor;
      }

      button + button {
        margin-left: 4px;
      }

      button:disabled {
        color: $accentColorDisabled;
      }

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}
