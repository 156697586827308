.students-page {
  padding: 64px 40px;

  &__group {
    display: flex;
    gap: 20px;
    margin-bottom: 40px;
  }
}

.statements-container__element.active {
  border-color: #4992ff;
  & > h2 {
    color: #4992ff;
  }
}

.student-page__list {
  width: 100%;
  margin: 10px 0 0;
  overflow: auto;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.list__item {
  min-height: 80px;
  font-weight: 700;
  cursor: pointer;
  font-size: 20px;
  line-height: 32px;
  color: #2b2b2b;
  border-bottom: 1px solid #e5e5e5;
  padding: 24px;
  overflow: auto;
}

.link {
  color: initial;
}
