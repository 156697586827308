.dropdown {
  position: relative;

  &__header {
    height: 40px;

    &-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 40px;
      background: $greyColorDark;
      border-radius: 4px;
      width: auto;
      cursor: pointer;
      &.rotate {
        transform: scaleY(-1);
      }
    }
  }

  &__main {
    position: absolute;
    top: 40px;
    width: max-content;
    background: #ffffff;
    border: 1px solid $greyColorDark;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15);
    border-radius: 4px;

    display: flex;
    flex-direction: column;
    &.left {
      left: 0;
    }
    &.right {
      right: 0;
    }

    .dropdown-element {
      padding: 10px;
      height: 40px;
      display: flex;
      align-items: center;

      gap: 8px;

      &:hover {
        background-color: $greyColorDarkHover;
      }
      &:active {
        background-color: $greyColorDarkPressed;
      }
    }
  }
}
