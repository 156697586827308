.breadcrumbs {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;

  &__button {
    min-width: 40px;
    height: 40px;
    transform: rotate(90deg);
  }
}
