.select-container {
  position: relative;
}

.select-container--active .select-container__placeholder {
  transform: translateY(-135%);
  font-size: 12px;
}

.select-container__placeholder {
  position: absolute;
  top: 10px;
  left: 0;
  transition: transform 150ms ease-out, font-size 150ms ease-out;
  cursor: text;
  user-select: none;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.select-container__placeholder--required::after {
  color: $dangerColor;
  content: '*';
}

.select-container__error {
  margin-top: 5px;
  font-size: 12px;
  color: $dangerColor;
}

.select-container--error .react-select__control {
  border-color: $dangerColor !important;
}

.select-container--error .react-select__control--is-focused {
  border-color: $dangerColor !important;
}

.react-select__control {
  border: none !important;
  border-bottom: 1px solid $greySecondaryColor !important;
  border-radius: unset !important;
  background-color: transparent !important;
}

.react-select__control--is-focused {
  box-shadow: none !important;
  border-color: $accentColor !important;
}

.react-select--is-disabled + .select-container__placeholder,
.react-select--is-disabled {
  opacity: 0.4;
}

.react-select__indicator-separator {
  display: none !important;
}

.react-select__indicator {
  color: $textColorDark !important;
  padding-right: 0 !important;
}

.react-select__value-container {
  padding: 2px 0 !important;
}

.react-select__option--is-selected {
  background-color: $accentColor !important;
  color: white !important;
}

.allSelected.react-select__option {
  background-color: $accentColor !important;
  color: white !important;
}
.allSelected.react-select__option--is-selected {
  background-color: white !important;
  color: $textColorDark !important;
}

.react-select__input-container {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
