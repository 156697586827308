.privileges-table {
  width: 100%;
  height: auto;
  border-collapse: collapse;

  thead {
    color: $textColor;
    font-size: 14px;
  }

  tbody {
    color: $textColorDark;
    font-size: 16px;
  }

  tr {
    height: 44px;
    border-bottom: 1px solid $greyColorDark;
  }
  td {
    vertical-align: middle;
    padding: 12px 24px 12px 0;
  }

  &__icons {
    display: grid;
    grid-template-columns: auto auto auto;
    column-gap: 8px;

    button {
      transition: 0.55s color;
    }

    button:hover {
      cursor: pointer;
      color: $accentColor;
    }

    button:disabled {
      color: $accentColorDisabled;
    }
  }
}
