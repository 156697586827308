.navbar {
  background-color: $greyColor;

  @include mobileScreen {
    display: none;
  }
}

.navbar__items {
  max-width: $contentMaxWidth;
  margin: 0 auto;
  display: flex;
  text-align: center;
  align-items: flex-end;
}

.navbar__item {
  flex: 1;
}

.navbar__link {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: $fontWeightBold;
  color: $textColorDark;
  padding: 15px;
}

.navbar__link:hover {
  color: $accentColor;
}

.navbar__link.active {
  border-bottom-width: 2px;
  border-color: $accentColor;
}
