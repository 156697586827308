.directory-page {
  margin: 0 15px;
  .directory-page-header {
    margin: 64px 0 24px;
    display: flex;
    justify-content: space-between;
  }
  &__title {
    font-size: 24px;
    line-height: 32px;
  }
  &__pagination {
    margin-top: 20px;
  }
}
