.create-page {
  margin: 40px 40px 20px;

  &__title {
    font-size: 24px;
    margin-bottom: 24px;
  }

  &__header {
    display: flex;
    width: 100%;

    &.row {
      flex-direction: row;
      justify-content: space-between;
    }
    &.column {
      flex-direction: column;
    }

    .button {
      margin-bottom: 24px;
      padding: 10px;
    }
  }

  @include mobileScreen {
    margin: 0 15px;

    &__header {
      flex-direction: column !important;
    }

    &__title {
      margin: 30px 0;
    }
    & .button {
      width: 100%;
    }
  }
}
