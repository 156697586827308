.button {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 150px;
  padding: 15px;
  border-radius: $borderRadiusSm;
  font-size: 14px;
  text-transform: uppercase;
  color: $textColorDark;
  font-weight: $fontWeightBold;
  background-color: $greyColorDark;
  transition: $transitionFast;
  transition-property: background-color;

  &:hover {
    background-color: $greyColorDarkHover;
  }
  &:active {
    background-color: $greyColorDarkPressed;
  }
  &:disabled {
    color: $accentColorDisabled;
    background: transparent;
    pointer-events: none;
  }
}

.button--theme-success {
  color: white;
  background-color: $accentColor;

  &:hover {
    color: white;
    background-color: $accentColorHover;
  }
  &:active {
    color: white;
    background-color: $accentColorActive;
  }
  &:disabled {
    color: $greySecondaryColor;
    background-color: $accentColorDisabled;
    pointer-events: none;
  }
}
