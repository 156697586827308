.moderator-contract-id-page {
  margin: 0 5px;

  &__title {
    margin: 64px 0 24px;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
  }
}
